.banner {
  background-color: var(--color-indigo-100);
  display: flex;
  gap: var(--space-2-5x);
  justify-content: center;
  align-items: center;
  color: var(--color-black);
  padding: var(--space-3x);
  font-size: 14px;
  font-weight: var(--font-weight-semibold);
  --icon-button-display: flex;

  @media (--screen-md) {
    --button-height: 100%;
    font-size: 16px;
  }
}

.bannerIcon {
  width: 25px;
  height: 25px;
}

.bannerLink {
  text-wrap: nowrap;
}
