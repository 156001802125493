@layer components {
  .mapSkeleton {
    background: var(--color-gray-100);
    flex-grow: 1;
    height: 100%;
  }

  .mapElement {
    flex-grow: 1;
    height: 100%;
    border-radius: var(--map-border-radius);
  }

  .withFullWidthHeader.mapElement {
    @media (--screen-md) {
      --map-border-radius: 10px 0 0 0;
    }
  }
}
